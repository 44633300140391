<template>
  <div style="width: 100%; padding-top: 40px; padding-bottom: 44px; background-color: #fff;">
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="el-icon-s-home"></i>
          首页
        </el-breadcrumb-item>
        <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
        <el-breadcrumb-item>正文</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content-title">
        {{ contentData.title }}
      </div>
      <div class="content-line"></div>
      <div class="content-time">
        时间 : {{ dayjs(contentData.createTime).format('YYYY-MM-DD') }}
      </div>
      <div class="content-container" style="margin-top: 34px;" v-html="contentData.content"></div>
    </div>
  </div>
</template>
<script>
import { getArticle } from '@/api/indexApi.js'
export default {
  data() {
    return {
      dayjs: '',
      id: 0,
      contentData: {},
    }
  },
  created() {
    this.dayjs = require('dayjs')
    this.id = this.$route.query.id
    // console.log(this.$route)
    this.init()
  },
  updated() {
    $('.content-container').find('p').css('fontSize', '18px')
    $('.content-container').find('p').css('lineHeight', '2')
    $('.content-container').find('p').css('fontFamily', 'Microsoft YaHei')
    $('.content-container').find('span').css('fontFamily', 'Microsoft YaHei')
    $('.content-container').find('span').css('fontSize', '16px')
    $('.content-container').find('span').css('lineHeight', '2')
    $('.content-container').find('img').css('maxWidth', '1200px')
    $('.content-container').find('img').css('height', 'auto')
  },
  methods: {
    async init() {
      getArticle(this.id).then((res) => {
        
        if (res.code === 0) {
          this.contentData = res.result
          console.log(this.contentData)
        }
      })
    },
  },
  components: {},
}
</script>
<style lang="less" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  .content-title {
    font-size: 30px;
    font-family: MicrosoftYaHei-Bold;
    line-height: 36px;
    color: #e35513;
    text-align: center;
    margin-top: 61px;
  }
  .content-line {
    width: 1200px;
    border: 0.5px solid #999;
    margin-top: 12px;
  }
  .content-time {
    width: 1200px;
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    line-height: 36px;
    color: #999999;
    margin-top: 19px;
  }
}
</style>
