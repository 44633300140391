import axios from "./http";

// 首页轮播图
export function PostBanners() {
    return axios({
        url: "/datalyg/view/banners",
        method: "post"
    })
}

// 查询首页基础信息
export function PostIndex() {
    return axios({
        url: "/datalyg/view/index",
        method: "post"
    })
}

//文章
export function getArticle(type) {
    return axios({
        url: `/datalyg/view/article/${type}`,
        method: "GET",
    })
}

